module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_q7xwp2uxjvq5qwjqmzomcktpci/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Mākslīgā zāle","short_name":"Mākslīgā zāle","start_url":"/","background_color":"#ffcb00","theme_color":"#ffcb00","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"267e924b1d71be781632725245005996"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react_ufbfjcr7ipb4ax74o42t25anwa/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WD6N6BJV","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@_s25ybdgycmlqfvjzesks3inugq/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3._tteb5i5lblirbjv6djnx46qdna/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__kfe7wfm7jx55tvs4kjbtdtrpoa/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
